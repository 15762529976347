<template>
  <b-card>
    <div>
      <b-row>
        <b-col>
          <!-- search input -->
          <div
            class="custom-search d-flex align-items-center justify-content-end"
          >
            <div
              class="d-flex flex-column flex-sm-row align-items-center mb-1 justify-content-around"
            >
              <template
                v-if="$permissionAbility(LEAD_STATUS_CREATE, permissions)"
              >
                <b-button
                  class="flex-shrink-0 mr-1 btn-sm"
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="primary"
                  v-on:click="showModal"
                >
                  Create
                </b-button>
              </template>
            </div>
          </div>
        </b-col>
      </b-row>

      <!-- table -->
      <vue-good-table
        mode="remote"
        styleClass="vgt-table table-custom-style striped"
        @on-page-change="onPageChange"
        @on-sort-change="onSortChange"
        @on-column-filter="onColumnFilter"
        @on-per-page-change="onPerPageChange"
        :totalRows="totalRecords"
        :isLoading.sync="isLoading"
        :rows="rows"
        :sort-options="{
          enabled: false,
          multipleColumns: true,
          initialSortBy: [{ field: 'created_at', type: 'desc' }],
        }"
        :columns="columns"
        :pagination-options="{
          enabled: true,
          perPage: pageLength,
        }"
      >
        <template slot="table-row" slot-scope="props">
          <!-- format_members -->
          <template v-if="props?.column?.field === 'format_title'">
            <div class="table-cell-margin">
              <span class="font-weight-bold">{{ props?.row?.title }}</span>
            </div>
          </template>

          <span v-if="props.column.field === 'format_type'">
            <template v-if="!props?.row?.is_backlog">
              <b-badge
                :variant="
                  props?.row?.is_final ? 'light-danger' : 'light-primary'
                "
              >
                {{ props?.row?.is_final ? "Final" : "General" }}
              </b-badge>
            </template>
            <template v-else-if="props?.row?.is_backlog">
              <b-badge variant="light-info"> Initial </b-badge>
            </template>
          </span>

          <template
            v-if="props?.column?.field === 'format_task_sub_task_count'"
          >
            <div class="mt-1">
              <b>Task: </b>
              <b-badge pill variant="light-primary">{{
                props?.row?.task_count
              }}</b-badge>
            </div>

            <div class="mt-1">
              <b>Sub Task: </b>

              <b-badge pill variant="light-primary">{{
                props?.row?.sub_task_count
              }}</b-badge>
            </div>
          </template>
          <!-- Column: Action -->
          <span
            v-if="props.column.field === 'action' && !props?.row?.is_backlog"
          >

            <feather-icon
                v-on:click="onShow(props.row)"
                v-b-tooltip.hover
                icon="Edit2Icon"
                class="mr-50 custom-icon cursor-pointer"
                title="Edit"
                size="16"
            />

            <feather-icon
                v-on:click="onDelete(props.row.id)"
                v-b-tooltip.hover
                icon="TrashIcon"
                class="mr-50 custom-icon cursor-pointer"
                title="Delete"
                size="16"
            />

          </span>

          <!-- Column: Common -->
          <span v-else>
            {{ props.formattedRow[props.column.field] }}
          </span>
        </template>

        <!-- pagination -->
        <template slot="pagination-bottom" slot-scope="props">
          <div class="d-flex justify-content-between flex-wrap">
            <div class="d-flex align-items-center mb-0 mt-1">
              <span class="text-nowrap"> Showing 1 to </span>
              <b-form-select
                v-model="pageLength"
                :options="['10', '25', '50', '100', '500']"
                class="mx-1"
                @input="
                  (value) => props.perPageChanged({ currentPerPage: value })
                "
              />
              <span class="text-nowrap"> of {{ props.total }} entries </span>
            </div>
            <div>
              <b-pagination
                :value="1"
                :total-rows="props.total"
                :per-page="pageLength"
                first-number
                last-number
                align="right"
                prev-class="prev-item"
                next-class="next-item"
                class="mt-1 mb-0"
                @input="(value) => props.pageChanged({ currentPage: value })"
              >
                <template #prev-text>
                  <feather-icon icon="ChevronLeftIcon" size="18" />
                </template>
                <template #next-text>
                  <feather-icon icon="ChevronRightIcon" size="18" />
                </template>
              </b-pagination>
            </div>
          </div>
        </template>
      </vue-good-table>
    </div>

    <b-modal
      id="modal-task-board-phase"
      centered
      :title="
        modelType == 'editModel' ? 'Edit Phase Information ' : 'Create Phase'
      "
      hide-footer
      @hidden="hiddenModal"
      no-close-on-backdrop
    >
      <validation-observer ref="milestoneTaskBoardPhase">
        <b-form v-on:submit.prevent="milestoneTaskBoardPhaseForm">
          <!-- select milestone-->
          <b-form-group label="Title *" label-for="title">
            <ValidationProvider
              name="title"
              v-slot="{ errors }"
              vid="title"
              rules="required"
            >
              <b-form-input
                id="title"
                type="text"
                v-model="title"
                :state="errors.length > 0 ? false : null"
                placeholder="Phase Title"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </ValidationProvider>
          </b-form-group>
          <!-- Title -->
          <b-form-group label="Type *" label-for="type">
            <validation-provider
              #default="{ errors }"
              name="is_final"
              vid="is_final"
              rules="required|max:255"
            >
              <v-select
                id="is_final"
                placeholder="Choose a Type"
                v-model="selectTypeValue"
                :options="typeValueOption"
                :reduce="(country) => country.value"
                label="name"
              >
              </v-select>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <!-- loading button -->
          <template v-if="isLoading">
            <b-button class="float-right" variant="primary" disabled>
              <b-spinner small />
              Loading...
            </b-button>
          </template>

          <!-- submit button -->
          <template v-else>
            <b-button
              type="submit"
              class="float-right"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
            >
              Submit
            </b-button>
          </template>
        </b-form>
      </validation-observer>
    </b-modal>
  </b-card>
</template>

      <script>
      import {
        BCard,
        BAvatar,
        BBadge,
        BPagination,
        BFormGroup,
        BFormInput,
        BFormSelect,
        BDropdown,
        BDropdownItem,
        BButton,
        BForm,
        BModal,
        BSpinner,
        BLink,
        BBreadcrumb,
        BBreadcrumbItem,
        BRow,
        BCol, VBTooltip,
      } from "bootstrap-vue";
import { VueGoodTable } from "vue-good-table";
import Ripple from "vue-ripple-directive";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required, max, min } from "@validations";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { mapGetters } from "vuex";
import {
  LEAD_STATUS_ACCESS,
  LEAD_STATUS_CREATE,
  LEAD_STATUS_EDIT,
  LEAD_STATUS_DELETE,
} from "@/helpers/permissionsConstant";
import LvColorPicker from "lightvue/color-picker";
import { leadSequenceConstants } from "@/helpers/constant/leadSequenceConstant";
import { leadStatusTypeConstants } from "@/helpers/constant/leadStatusTypeConstant";

export default {
  name: "MilestoneTaskBoardListView",
  components: {
    LvColorPicker,
    BForm,
    BButton,
    BCard,
    BBadge,
    VueGoodTable,
    BAvatar,
    BBadge,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    ValidationProvider,
    ValidationObserver,
    BModal,
    BSpinner,
    BLink,
    BBreadcrumb,
    BBreadcrumbItem,
    BRow,
    BCol,
  },
  directives: {
    Ripple,
    'b-tooltip': VBTooltip,
  },
  data() {
    return {
      LEAD_STATUS_ACCESS,
      LEAD_STATUS_CREATE,
      LEAD_STATUS_EDIT,
      LEAD_STATUS_DELETE,

      modelType: "",

      boardInfo: "",
      //phase
      phaseId: "",
      title: "",
      selectTypeValue: false,
      typeValueOption: [
        { name: "General", value: false },
        { name: "Final", value: true },
      ],

      pageLength: 10,
      columns: [
        {
          label: "Title",
          field: "format_title",
          sortable: false,
        },

        {
          label: "Type",
          field: "format_type",
          sortable: false,
        },
        {
          label: "Task & Sub Task Count",
          field: "format_task_sub_task_count",
          sortable: false,
        },
        {
          label: "Created On",
          field: "created_at",
          sortable: false,
        },
        {
          label: "Action",
          field: "action",
          sortable: false,
        },
      ],
      rows: [],
      delayTimer: null,
      isLoading: false,
      totalRecords: 0,
      serverParams: {
        columnFilters: {},
        sort: [
          // { field: "id", type: "desc" },
          // { field: "name", type: "desc" },
          { field: "created_at", type: "desc" },
        ],
        page: 1,
        perPage: 50,
      },
    };
  },
  computed: {
    ...mapGetters({
      permissions: "userModule/getPermissions",
    }),
  },

  async created() {},
  methods: {
    onShowMilestones() {
      this.$router.push({
        name: "admin-milestones",
      });
    },
    onShowDepartmentDetails() {
      const id = this.boardInfo?.department?.data?.id;
      this.$router.push({
        name: "admin-department-details",
        params: { id },
      });
    },

    onShowPage() {
      const id = this.$route.params.id;
      this.$router.push({
        name: "admin-task-board-details",
        params: { id },
      });
    },
    formatMilestoneType(value) {
      if (value) {
        return "Extra";
      }
      return "Primary";
    },
    formatType(value) {
      if (value) {
        return value;
      }
    },
    goToTrash() {
      const id = this.$route.params.id;
      this.$router.push({
        name: "admin-task-board-phases-trash",
        params: { id },
      });
    },
    showModal() {
      this.$bvModal.show("modal-task-board-phase");
    },
    hiddenModal() {
      this.$bvModal.hide("modal-task-board-phase");
      this.resetModal();
    },

    resetModal() {
      this.modelType = "";
      this.phaseId = "";
      this.title = "";
      this.selectTypeValue = false;
    },

    onShow(value) {
      this.modelType = "editModel";
      this.phaseId = value?.id;
      this.title = value?.title;
      this.selectTypeValue = value?.is_final;

      this.showModal();
    },

    updateParams(newProps) {
      this.serverParams = Object.assign({}, this.serverParams, newProps);
    },

    onPageChange(params) {
      this.updateParams({ page: params.currentPage });
      this.loadItems();
    },

    onPerPageChange(params) {
      this.updateParams({ perPage: params.currentPerPage });
      this.loadItems();
    },

    onSortChange(params) {
      this.params = (params || []).map((item) => {
        let field = item.field;

        // if (item.field == "format_title") {
        //   field = "title";
        // }

        // if (item.field == "format_email") {
        //   field = "email";
        // }

        return {
          field: field,
          type: item.type,
        };
      });

      this.updateParams({
        sort: this.params,
      });
      this.loadItems();
    },

    onColumnFilter(params) {
      this.updateParams(params);
      this.loadItems();
    },

    async onDelete(id) {
      const milestoneTaskBoardId = this.$route.params.id;
      this.$swal({
        title: "Warning!",
        text: "Are You Sure You Want To Delete This?",
        icon: "warning",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
        showCancelButton: true,
        confirmButtonText: "Ok",
        showLoaderOnConfirm: true,
      }).then(async (result) => {
        if (result.isConfirmed) {
          try {
            await this.$api.delete(
              `/api/milestone-task-boards/${milestoneTaskBoardId}/phases/${id}/delete`
            );
            this.loadItems();
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Success",
                icon: "BellIcon",
                variant: "success",
                text: "Phase successfully deleted",
              },
            });
          } catch (error) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Error",
                icon: "BellIcon",
                variant: "danger",
                text: error?.response?.data?.message,
              },
            });
          }
        }
      });
    },

    async getMilestoneBoardPhases(params) {
      const milestoneTaskBoardId = this.$route.params.id;
      return await this.$api.get(
        `api/milestone-task-boards/${milestoneTaskBoardId}/phases`,
        {
          params: {
            show: params.show,
            page: params.page,
            sort: params.sort,
            q: params.q,
          },
        }
      );
    },
    async getBoardInfo() {
      const milestoneTaskBoardId = this.$route.params.id;
      return await this.$api.get(
        `/api/milestone-task-boards/${milestoneTaskBoardId}?include=department,milestone`
      );
    },

    async loadItems() {
      try {
        const [phaseList, boardInfo] = await Promise.all([
          this.getMilestoneBoardPhases({
            show: this.serverParams.perPage,
            page: this.serverParams.page,
            sort: this.serverParams.sort,
            q: this.searchTerm,
          }),
          this.getBoardInfo(),
        ]);

        this.boardInfo = boardInfo?.data?.data;
        const data = phaseList?.data?.data;

        this.rows = data;

        const meta = phaseList?.data?.meta;
        this.totalRecords = meta?.pagination?.total;
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Warning",
            icon: "BellIcon",
            variant: "warning",
            text: error?.response?.data?.message,
          },
        });
      }
    },

    milestoneTaskBoardPhaseForm: async function () {
      this.$refs.milestoneTaskBoardPhase.validate().then(async (success) => {
        if (success) {
          try {
            this.$refs.milestoneTaskBoardPhase.reset();
            if (this.modelType == "editModel") {
              this.isLoading = true;
              const milestoneTaskBoardId = this.$route.params.id;
              if (this.selectTypeValue == true) {
                this.$swal({
                  title: "Warning!",
                  text: `This Phase  Will Be Set As Final!`,
                  icon: "warning",
                  customClass: {
                    confirmButton: "btn btn-primary",
                    cancelButton: "btn btn-outline-danger ml-1",
                  },
                  buttonsStyling: false,
                  showCancelButton: true,
                  confirmButtonText: "Ok",
                  showLoaderOnConfirm: true,
                }).then(async (result) => {
                  if (result.isConfirmed) {
                    try {
                      await this.$api.put(
                        `/api/milestone-task-boards/${milestoneTaskBoardId}/phases/${this.phaseId}/update`,
                        {
                          title: this.title,
                          is_final: this.selectTypeValue,
                        }
                      );
                      this.isLoading = false;
                      this.loadItems();
                      this.hiddenModal();
                      this.$toast({
                        component: ToastificationContent,
                        props: {
                          title: "Success",
                          icon: "BellIcon",
                          variant: "success",
                          text: "Phase Information Updated Successfully",
                        },
                      });
                    } catch (error) {
                      this.isLoading = false;

                      if (error?.response?.data?.message) {
                        this.$toast({
                          component: ToastificationContent,
                          props: {
                            title: "Error",
                            icon: "BellIcon",
                            variant: "danger",
                            text: error?.response?.data?.message,
                          },
                        });
                      }
                    }
                  }
                });
                this.isLoading = false;
              } else {
                try {
                  await this.$api.put(
                    `/api/milestone-task-boards/${milestoneTaskBoardId}/phases/${this.phaseId}/update`,
                    {
                      title: this.title,
                      is_final: this.selectTypeValue,
                    }
                  );
                  this.isLoading = false;
                  this.loadItems();
                  this.hiddenModal();
                  this.$toast({
                    component: ToastificationContent,
                    props: {
                      title: "Success",
                      icon: "BellIcon",
                      variant: "success",
                      text: "Phase Information Updated Successfully",
                    },
                  });
                } catch (error) {
                  this.isLoading = false;

                  if (error?.response?.data?.message) {
                    this.$toast({
                      component: ToastificationContent,
                      props: {
                        title: "Error",
                        icon: "BellIcon",
                        variant: "danger",
                        text: error?.response?.data?.message,
                      },
                    });
                  }
                }
              }

              this.isLoading = false;
            } else {
              this.isLoading = true;
              const milestoneTaskBoardId = this.$route.params.id;
              if (this.selectTypeValue == true) {
                this.$swal({
                  title: "Warning!",
                  text: "This Phase  Will Be Set As Final!",
                  icon: "warning",
                  customClass: {
                    confirmButton: "btn btn-primary",
                    cancelButton: "btn btn-outline-danger ml-1",
                  },
                  buttonsStyling: false,
                  showCancelButton: true,
                  confirmButtonText: "Ok",
                  showLoaderOnConfirm: true,
                }).then(async (result) => {
                  if (result.isConfirmed) {
                    try {
                      await this.$api.post(
                        `/api/milestone-task-boards/${milestoneTaskBoardId}/phases/store`,
                        {
                          title: this.title,
                          is_final: this.selectTypeValue,
                        }
                      );
                      this.isLoading = false;
                      this.loadItems();
                      this.hiddenModal();
                      this.$toast({
                        component: ToastificationContent,
                        props: {
                          title: "Success",
                          icon: "BellIcon",
                          variant: "success",
                          text: "New Phase Created Successfully",
                        },
                      });
                    } catch (error) {
                      this.isLoading = false;

                      if (error?.response?.data?.message) {
                        this.$toast({
                          component: ToastificationContent,
                          props: {
                            title: "Error",
                            icon: "BellIcon",
                            variant: "danger",
                            text: error?.response?.data?.message,
                          },
                        });
                      }
                    }
                  }
                });

                this.isLoading = false;
              } else {
                await this.$api.post(
                  `/api/milestone-task-boards/${milestoneTaskBoardId}/phases/store`,
                  {
                    title: this.title,
                    is_final: this.selectTypeValue,
                  }
                );
                this.isLoading = false;
                this.loadItems();
                this.hiddenModal();
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: "Success",
                    icon: "BellIcon",
                    variant: "success",
                    text: "New Phase Created Successfully",
                  },
                });
              }
            }
          } catch (error) {
            this.isLoading = false;

            if (error?.response?.data?.message) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: "Error",
                  icon: "BellIcon",
                  variant: "danger",
                  text: error?.response?.data?.message,
                },
              });
            }
            if (error?.response?.data?.errors) {
              this.$refs.milestoneTaskBoardPhase.setErrors(
                error?.response?.data?.errors
              );
            }
          }
        }
      });
    },
  },
};
</script>
      <style lang="scss">
@import "@core/scss/vue/libs/vue-good-table.scss";

.table-custom-style {
  font-size: 13px !important;
  white-space: nowrap !important;
  min-height: 140px !important;
  tr,
  th,
  td {
    vertical-align: left !important;
    text-align: left !important;
  }
}
.table-cell-margin {
  margin: 8px 0px;
}
</style>





